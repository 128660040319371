import React from "react";
import Banner from "../Banner/Banner";
import dashboard from "../../assest/dashboard.png";
import "./Left.scss";
import { useNavigate } from "react-router-dom";
// Images
import help from "../../assest/Bottom/help.png";
import forum from "../../assest/Bottom/forum.png";
import arrowup from "../../assest/Bottom/arrowup.png";

const Left = () => {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('admin'))
  return (
    <>
      <div className="left">
        <div className="left-container">
          <div className="left-container-top">
            <div className="left-container-top-banner" onClick={()=>navigate('/dashboardTwo')} style={{cursor:"pointer"}}>
              <Banner />
            </div>
            <div className="left-container-top-menu">
              <div className="left-container-top-menu-item" onClick={()=>navigate('/dashboardTwo')} style={{cursor:"pointer"}}>
                <img src={dashboard} alt="" />
                <h4>Dashboard</h4>
              </div>
            </div>
          </div>
          <div className="left-container-bottom">
            <div className="left-container-bottom-content">
              {/* <div className="left-container-bottom-content-item">
                <img src={help} alt="" />
                <h5>Help</h5>
              </div> */}
              {/* <div className="left-container-bottom-content-item">
                <img src={forum} alt="" />
                <h5>Community</h5>
              </div> */}

              <div className="left-container-bottom-content-section">
                <div></div>
                <h3>
                  {user ? user.firstName: '...'}
                  <br />
                </h3>

                <img src={arrowup} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Left;

import React,{useEffect} from "react";
import "./NewMember.scss";

// Yup and Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
//  Components
import Left from "../../components/Left/Left";
import Middle from "../../components/Middle/Middle";
import Right from "../../components/Right/Right";
import Dropdown from "../../components/Dropdown/Dropdown";
import { sendInviteEmail,clearErrors,clearMessages } from "./../../store/actions";
// Input's For Form
import FormInput from "./Input";
import FormText from "./Text";
// Images
import send from "../../assest/send.svg";
import { useDispatch,useSelector } from "react-redux";

const NewMember = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const {message,errors,loading} = useSelector((state) => state.authReducer);
  const user = JSON.parse(localStorage.getItem("admin"));
  const validate = Yup.object({
    email: Yup.string().required("Required"),
  });

  useEffect(()=>{
    if(errors.length > 0){
      toast.error(errors)
      dispatch(clearErrors());
    }
    if (message) {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/dashboardTwo"), 3000);
    }
  },[errors,message])

  return (
    <>
     <Toaster
        toastOptions={{
          style: {
            fontSize: "18px",
          },
        }}
      />
      <div className="member">
        <div className="member-container">
          <div className="member-container-left">
            <Left />
          </div>
          <div className="member-container-middle">
            <Middle>
              <div className="member-container-middle-content">
                <div className="member-container-middle-content-heading">
                  <p>Welcome back</p>
                  <h2>{user ? `${user.firstName} ${user.lastName}` : "..."}</h2>
                </div>

                <div className="member-container-middle-content-main">
                  {/* Form Side  */}
                  <div className="member-container-middle-content-main-left">
                    <div className="member-container-middle-content-main-left-heading">
                      <h1>Add New Member</h1>
                    </div>
                    <div className="member-container-middle-content-main-left-form">
                      <Formik
                        initialValues={{
                          email: "",
                        }}
                        validationSchema={validate}
                        onSubmit={(values) => {
                          delete values.message
                          dispatch(sendInviteEmail(values));
                        }}
                      >
                        {(formik) => (
                          <div>
                            <Form>
                              <FormInput
                                label="Enter the new member’s email address*"
                                name="email"
                                type="text"
                              />
                              {/* <h6 className="dropdown-heading">Assign Role</h6>
                              <div className="form-dropdown">
                                <Dropdown />
                              </div> */}
                              {/* <FormText label="Email Text" name="message" /> */}
                              <div className="member-container-middle-content-main-right">
                                <div className="invite" style={{marginTop:"1.5rem"}}>
                                  <center>
                                  <button
                                    className="send-invite"
                                  >
                                    <img src={send} alt="" />
                                    {loading?"Loading":"Send Invite"}
                                  </button>
                                  </center>
                                </div>
                              </div>
                            </Form>
                          </div>
                        )}
                      </Formik>
                    </div>
                  </div>
                  {/* Button Side  */}
                </div>
              </div>
            </Middle>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewMember;

import React from "react";
import "./Sidebar.scss";
import { useNavigate } from "react-router-dom";
const Sidebar = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="sidebar">
        <div className="sidebar-container">
          <div className="sidebar-container-btn">
            <div className="sidebar-container-btn-first">
              {/* <button onClick={()=>navigate('/first')}>sign up</button> */}
            </div>
            <div className="sidebar-container-btn-second">
              {/* <button onClick={()=>navigate('/')}>sign in</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

import React from "react";
import "./FormInput.scss";
import { ErrorMessage, useField } from "formik";
const FormInput = ({ logo, label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="input">
        <div className="input-container">
          <div className="input-container-logo">
            <img src={logo} alt="icon" />
          </div>
          <input
            placeholder={label}
            type="text"
            className={`input-container-input ${
              meta.touched && meta.error && "is-invalid"
            }`}
            {...field}
            {...props}
            autoComplete="off"
          />
        </div>
        <ErrorMessage component="div" name={field.name} id="input-error" />
      </div>
    </>
  );
};

export default FormInput;

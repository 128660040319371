import React, { useEffect } from "react";
import "./SignUpThree.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Register, clearErrors, clearMessages } from "./../../store/actions";
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import Loading from "../../components/Loading/Loading";
import toast, { Toaster } from "react-hot-toast";
import logo from "../../assest/login/logo.svg";
import people from "../../assest/login/people.svg";
import home from "../../assest/login/home.svg";

const SignUpThree = () => {
  const navigate = useNavigate();
  let userFirstName = "";
  let userLastName = "";
  let userEmail = "";
  let userPassword = "";
  const { state } = useLocation();
  if (state) {
    const { firstName, lastName, values } = state;
    const { email, password } = values;
    userFirstName = firstName;
    userLastName = lastName;
    userEmail = email;
    userPassword = password;
  }
  const dispatch = useDispatch();
  const { message, errors, loading } = useSelector(
    (state) => state.authReducer
  );
  const validate = Yup.object({
    contactNo: Yup.string().optional(),
    address: Yup.string().optional(),
  });

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/signup"), 2000);
    }
  }, [message, errors]);
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            fontSize: "18px",
          },
        }}
      />
      <div className="signup-three">
        <div className="signup-three-container">
          <div className="signup-three-container-left">
            <Sidebar />
          </div>
          <div className="signup-three-container-right">
            <div className="signup-three-container-right-content">
              <div className="signup-three-container-right-content-logo">
                <img src={logo} alt="logo" />
                <br />
                <center>
                  <h3 style={{ color: "white" }}>ADEELBUX</h3>
                </center>
              </div>
              <div className="signup-three-container-right-content-heading">
                <center>
                  <h1 style={{ color: "white" }}>Create an Admin Account</h1>
                </center>
              </div>
              <div className="signup-three-container-right-content-form">
                <Formik
                  initialValues={{
                    contactNo: "",
                    address: "",
                  }}
                  validationSchema={validate}
                  onSubmit={(values) => {
                    const { contactNo, address } = values;
                    const user = {
                      firstName: userFirstName,
                      lastName: userLastName,
                      email: userEmail,
                      password: userPassword,
                      phone: contactNo,
                      address,
                    };
                    dispatch(Register(user));
                  }}
                >
                  {(formik) => (
                    <div>
                      <Form>
                        <FormInput
                          label="Enter Phone Number    (Optional)"
                          name="contactNo"
                          type="text"
                          logo={people}
                        />
                        <FormInput
                          label="Enter Address               (Optional)"
                          name="address"
                          type="text"
                          logo={home}
                        />
                        <div className="signup-three-container-right-content-form-btn">
                          <Button
                            type="password"
                            text={loading ? "Signing" : "Next"}
                            shape="btn-first"
                          />
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
              <div className="signup-three-container-right-content-loading">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpThree;

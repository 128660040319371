import React,{useEffect} from 'react'
import "./App.scss";
import Routess from './Routes'
function App() {
  return (
    <>
    <Routess/>
    </>
  );
}

export default App;

import React from "react";
import "./Button.scss";

const Button = ({ shape, text, ...props }) => {
  return (
    <>
      <button className={shape} {...props}>
        {text}
      </button>
    </>
  );
};

export default Button;

import { authConstant } from "./../constants";

const initialState = {
  users: [],
  admins: [],
  errors: [],
  history: [],
  userBalanced: 0,
  loading: false,
  logOutLoading: false,
  totalPagesOfUser: 1,
  totalPagesOfAdmin: 1,
  message: "",
  verifyTokenFail: false,
  showAlert: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstant.USER_REGISTER_REQUEST:
    case authConstant.SUSPEND_ACCOUNT_REQUEST:
    case authConstant.USER_LOGIN_REQUEST:
    case authConstant.GET_ALL_USERS_REQUEST:
    case authConstant.ADMIN_HISTORY_REQUEST:
    case authConstant.SEND_INVITE_REQUEST:
    case authConstant.CREDIT_ACCOUNT_REQUEST:
    case authConstant.DEBIT_ACCOUNT_REQUEST:
    case authConstant.FORGET_PASSWORD_REQUEST:
    case authConstant.VERIFY_OTP_REQUEST:
    case authConstant.CHANGE_PASSWORD_REQUEST:
    case authConstant.EXPORT_USER_REQUEST:
    case authConstant.GET_ALL_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
      break;
    case authConstant.LOGOUT_REQUEST:
      return {
        ...state,
        logOutLoading: true,
      };
      break;
    case authConstant.USER_REGISTER_SUCCESS:
    case authConstant.SUSPEND_ACCOUNT_SUCCESS:
    case authConstant.USER_LOGIN_SUCCESS:
    case authConstant.EMAIL_SENT_SUCCESS:
    case authConstant.SEND_INVITE_SUCCESS:
    case authConstant.CREDIT_ACCOUNT_SUCCESS:
    case authConstant.DEBIT_ACCOUNT_SUCCESS:
    case authConstant.FORGET_PASSWORD_SUCCESS:
    case authConstant.VERIFY_OTP_SUCCESS:
    case authConstant.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        showAlert: true,
        loading: false,
        message: action.payload,
      };
      break;
    case authConstant.LOGOUT_SUCCESS:
      return {
        ...state,
        logOutLoading: false,
        message: action.payload,
      };
      break;
    case authConstant.VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        verifyTokenFail: true,
      };
    case authConstant.USER_REGISTER_FAILURE:
    case authConstant.SUSPEND_ACCOUNT_FAILURE:
    case authConstant.ADMIN_HISTORY_FAILURE:
    case authConstant.GET_ALL_USERS_FAILURE:
    case authConstant.USER_LOGIN_FAILURE:
    case authConstant.SEND_INVITE_FAILURE:
    case authConstant.CREDIT_ACCOUNT_FAILURE:
    case authConstant.DEBIT_ACCOUNT_FAILURE:
    case authConstant.FORGET_PASSWORD_FAILURE:
    case authConstant.VERIFY_OTP_FAILURE:
    case authConstant.CHANGE_PASSWORD_FAILURE:
    case authConstant.EXPORT_USER_FAILURE:
    case authConstant.GET_ALL_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.err,
      };
      break;
    case authConstant.LOGOUT_FAILURE:
      return {
        ...state,
        logOutLoading: false,
        errors: action.payload.err,
      };
      break;
    case authConstant.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.results,
        totalPagesOfUser: action.payload.totalPagesOfUser,
      };
      break;
      case authConstant.GET_ALL_ADMIN_SUCCESS:
        return {
          ...state,
          loading: false,
          admins: action.payload.results,
          totalPagesOfUser: action.payload.totalPagesOfUser,
        };
        break;
    case authConstant.ADMIN_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.payload.results,
        totalPagesOfAdmin: action.payload.totalPagesOfAdmin,
      };
      break;
    case authConstant.CLEAR_MESSAGES:
      return {
        ...state,
        loading: false,
        message: "",
        showAlert: false,
      };
      break;
    case authConstant.CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: [],
        showAlert: false,
      };
      break;
    default:
      return state;
  }
};

export default authReducer;

import { Button } from "bootstrap";
import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";

export default function MyVerticallyCenteredModal(props) {
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  
  return (
    <Modal
      {...props}
      size="small"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.transName?"Subtract":"Add"} Amount
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pop-modal">
        <input
          type="number"
          placeholder="Enter Amount"
          onChange={(e) => setAmount(e.target.value)}
          style={{ fontSize: "1.5rem", border: "1px solid black" }}
        />
        <input
          type="text"
          placeholder="Add Description"
          style={{
            fontSize: "1.5rem",
            border: "1px solid black",
            marginTop: "2rem",
          }}
          onChange={(e) => setDescription(e.target.value)}
        />
        <center>
          <button
            className="send-btn"
            onClick={() => props.onHide(amount, description,setAmount,setDescription)}
          >
            Save
          </button>
        </center>
      </Modal.Body>
    </Modal>
  );
}

import React from "react";
import "./Banner.scss";
import banner from "../../assest/banner.png";

const Banner = () => {
  return (
    <>
      <div className="banner">
        <div className="banner-container">
          <img src={banner} alt="" />
          <h4>adeelbux</h4>
        </div>
      </div>
    </>
  );
};

export default Banner;

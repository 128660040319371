import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";

import "./TokenNotValid.scss";
const AccessDenied = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="page_404">
        <div className="row">
          <div className="col-sm-12 ">
            <center>
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">Link Expired</h1>
                </div>
                <center>
                  <div className="contant_box_404" style={{ fontSize: "4rem" }}>
                    <h3 className="h2">This link has now expired.</h3>
                    <p style={{ fontSize: "4rem" }}>
                      The page you are looking for is not available anymore.
                    </p>
                    <br />
                    <div onClick={() => navigate("/login")}>
                      <Button shape="btn-first" text="Take me home" />
                    </div>
                  </div>
                </center>
              </div>
            </center>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccessDenied;

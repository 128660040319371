import React from "react";
import "./Right.scss";

const Right = ({ children }) => {
  return (
    <>
      <div className="right">
        <div className="right-container">{children}</div>
      </div>
    </>
  );
};

export default Right;

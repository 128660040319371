import React, { useEffect } from "react";
import "./ForgetPasswords.scss";
import logo from "../../assest/login/logo.svg";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
const ForgetPassword = () => {
  const navigate = useNavigate();
  useEffect(() => {
    alert("Please check your email to reset the password!");
    navigate("/");
  }, []);
  return (
    <>
      <div className="forget">
        <div className="forget-content">
          <div className="forget-content-logo">
            <img src={logo} alt="logo" />
            <br />
            <center>
              <h3 style={{ color: "white" }}> ADEELBUX</h3>
            </center>
          </div>
          <div
            className="forget-content-heading"
            onClick={() => navigate("/")}
          >
            <Button text="Login" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;

import React from "react";
import "./Input.scss";
import { ErrorMessage, useField } from "formik";
const FormInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="newinput">
        <label htmlFor="">{label}</label>
        <div className="newinput-container">
          <input
            type="text"
            className={`newinput-container-input ${
              meta.touched && meta.error && "is-invalid"
            }`}
            {...field}
            {...props}
            autoComplete="off"
          />
        </div>
        <ErrorMessage component="div" name={field.name} id="newinput-error" />
      </div>
    </>
  );
};

export default FormInput;

export const authConstant = {
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAILURE: "USER_REGISTER_FAILURE",
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  ADMIN_HISTORY_REQUEST: "ADMIN_HISTORY_REQUEST",
  ADMIN_HISTORY_SUCCESS: "ADMIN_HISTORY_SUCCESS",
  ADMIN_HISTORY_FAILURE: "ADMIN_HISTORY_FAILURE",
  SEND_INVITE_REQUEST: "SEND_INVITE_REQUEST",
  SEND_INVITE_SUCCESS: "SEND_INVITE_SUCCESS",
  SEND_INVITE_FAILURE: "SEND_INVITE_FAILURE",
  GET_ALL_USERS_REQUEST: "GET_ALL_USERS_REQUEST",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",
  CREDIT_ACCOUNT_REQUEST: "CREDIT_ACCOUNT_REQUEST",
  CREDIT_ACCOUNT_SUCCESS: "CREDIT_ACCOUNT_SUCCESS",
  CREDIT_ACCOUNT_FAILURE: "CREDIT_ACCOUNT_FAILURE",
  SUSPEND_ACCOUNT_REQUEST: "SUSPEND_ACCOUNT_REQUEST",
  SUSPEND_ACCOUNT_SUCCESS: "SUSPEND_ACCOUNT_SUCCESS",
  SUSPEND_ACCOUNT_FAILURE: "SUSPEND_ACCOUNT_FAILURE",
  CREDIT_ACCOUNT_REQUEST: "CREDIT_ACCOUNT_REQUEST",
  CREDIT_ACCOUNT_SUCCESS: "CREDIT_ACCOUNT_SUCCESS",
  CREDIT_ACCOUNT_FAILURE: "CREDIT_ACCOUNT_FAILURE",
  DEBIT_ACCOUNT_REQUEST: "DEBIT_ACCOUNT_REQUEST",
  DEBIT_ACCOUNT_SUCCESS: "DEBIT_ACCOUNT_SUCCESS",
  DEBIT_ACCOUNT_FAILURE: "DEBIT_ACCOUNT_FAILURE",
  FORGET_PASSWORD_REQUEST: "FORGET_PASSWORD_REQUEST",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_FAILURE: "FORGET_PASSWORD_FAILURE",
  VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  EMAIL_SENT_SUCCESS: "EMAIL_SENT_SUCCESS",
  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_MESSAGES: "CLEAR_MESSAGES",
  VERIFY_TOKEN_REQUEST: "VERIFY_TOKEN_REQUEST",
  VERIFY_TOKEN_SUCCESS: "VERIFY_TOKEN_SUCCESS",
  VERIFY_TOKEN_FAILURE: "VERIFY_TOKEN_FAILURE",
  EXPORT_USER_REQUEST: "EXPORT_USER_REQUEST",
  EXPORT_USER_SUCCESS: "EXPORT_USER_SUCCESS",
  EXPORT_USER_FAILURE: "EXPORT_USER_FAILURE",
  GET_ALL_ADMIN_REQUEST: "GET_ALL_ADMIN_REQUEST",
  GET_ALL_ADMIN_SUCCESS: "GET_ALL_ADMIN_SUCCESS",
  GET_ALL_ADMIN_FAILURE: "GET_ALL_ADMIN_FAILURE",
};

import React from "react";
import "./Loading.scss";
const Loading = () => {
  return (
    <>
      <div className="loading">
        <div className="loading-box">
          <div className="loading-box-line"></div>
        </div>
      </div>
    </>
  );
};

export default Loading;

import axios from 'axios'

const token = localStorage.getItem('adminToken')
const user = JSON.parse(localStorage.getItem("admin"));
// if(user){
//   window.reload()
// }


const api = axios.create({
    baseURL: process.env.REACT_APP_ROOT,
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json',
        Accept: 'application/json',
      'Authorization': token ? `Bearer ${token}` : ''
    },
    //   withCredentials: true,
    credentials: "same-origin",
  });



export const register = (data)=>api.post('/v1/app/admin',{...data})
export const login = (data)=>api.post('/v1/app/admin/login',{...data})
export const GetAllUsers = (page)=>api.get(`/v1/app/user?page=${page}&limit=8`)
export const CreditAccount = (id,amount,description)=>api.post(`/v1/app/account/credit/${id}`,{amount:amount,description})
export const DebitAccount = (id,amount,description)=>api.post(`/v1/app/account/debit/${id}`,{amount:amount,description})
export const GetLoginAdminHistory = (page)=>api.get(`/v1/app/transaction/admin/details?page=${page}&limit=8`)
export const LogOut = (token)=>api.post('/v1/app/admin/logout',{refreshToken:token})
export const SendInviteEmail = (data)=>api.post('/v1/app/user/invite/user',{...data})
export const SuspendAccount = (id,suspend)=>api.patch(`v1/app/user/${id}`,{suspend:suspend})
export const ForgetPassword = (data)=>api.post('/v1/app/admin/forgot/password',{...data})
export const VerifyOTP = (email,otp)=>api.post('/v1/app/admin/verify/otp',{email,otp})
export const ResetPassword = (id,newPassword)=>api.post('/v1/app/admin/reset/password',{token:id,newPassword})

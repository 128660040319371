import React, { useEffect } from "react";
import "./Login.scss";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Login, clearErrors, clearMessages } from "./../../store/actions";
// Yup and Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
//  Components
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import Loading from "../../components/Loading/Loading";

// Images from react icon
import mail from "../../assest/login/mail.svg";
import key from "../../assest/login/key.svg";
import logo from "../../assest/login/logo.svg";
const SignUpTwo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, errors, loading } = useSelector(
    (state) => state.authReducer
  );
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message !="") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/dashboardTwo"), 2000);
    }
  }, [errors, message]);

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            fontSize: "18px",
          },
        }}
      />
      <div className="signin">
        <div className="signin-container">
          <div className="signin-container-left">
            <Sidebar />
          </div>
          <div className="signin-container-right">
            <div className="signin-container-right-content">
              <div className="signup-two-container-right-content-logo">
                <img src={logo} alt="logo" />
                <br />
                <center>
                  <h3 style={{ color: "white" }}>ADEELBUX</h3>
                </center>
              </div>
              <div className="signin-container-right-content-heading">
                <h1>ADMIN LOGIN</h1>
              </div>
              <div className="signin-container-right-content-form">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={validate}
                  onSubmit={(values) => {
                    dispatch(Login(values));
                  }}
                >
                  {(formik) => (
                    <div>
                      <Form>
                        <FormInput
                          label="Enter your Email Address"
                          name="email"
                          type="email"
                          logo={mail}
                        />
                        <FormInput
                          label="Enter your Password"
                          name="password"
                          type="password"
                          logo={key}
                        />
                        <div className="forget-password">
                          <Link to="/forgetPassword">Forgot Password ?</Link>
                        </div>
                        <div className="signin-container-right-content-form-btn">
                          <Button
                            type="submit"
                            text={loading ? "Loading..." : "Login"}
                            shape="btn-first"
                          />
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
              <div className="signin-container-right-content-loading">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpTwo;

import React from "react";
import "./SignUp.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useNavigate, useLocation } from "react-router-dom";
// Yup and Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

//  Components
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import Loading from "../../components/Loading/Loading";

//  Images
import logo from "../../assest/login/logo.svg";
import edit from "../../assest/login/edit.svg";
// Icon from react icon

const SignUp = () => {
  const navigate = useNavigate();
  const validate = Yup.object({
    firstName: Yup.string()
      .min(2, "Name must be 2 character")
      .max(25, "Must be 25 characters or less")
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Name must be 2 character")
      .max(25, "Must be 25 characters or less")
      .required("Last name is required"),
  });

  const { state } = useLocation();
  let userFirstName = "";
  let userLastName = "";
  if (state != null) {
    let { firstName, lastName } = state;
    userFirstName = firstName;
    userLastName = lastName;
  }
  return (
    <>
      <div className="signup-page">
        <div className="signup-page-container">
          <div className="signup-page-container-left">
            <Sidebar />
          </div>
          <div className="signup-page-container-right">
            <div className="signup-page-container-right-content">
              {/* <div className="btn-forsign">
                <Button type="submit" text="Sign Up" shape="btn-first" />
                <Button type="submit" text="Sign In " shape="btn-second" />
              </div> */}
              <div className="signup-page-container-right-content-logo">
                <img src={logo} alt="logo" />
                <br />
                <center>
                  <h3 style={{ color: "white" }}>ADEELBUX</h3>
                </center>
              </div>
              <div className="signup-page-container-right-content-heading">
                <center>
                  <h1 style={{color:'white'}}>Create an Admin Account</h1>
                </center>
              </div>
              <div className="signup-page-container-right-content-form">
                <Formik
                  initialValues={{
                    firstName: userFirstName,
                    lastName: userLastName,
                  }}
                  validationSchema={validate}
                  onSubmit={(values) => {
                    navigate("/second", { state: values });
                  }}
                >
                  {(formik) => (
                    <div>
                      <Form>
                        <FormInput
                          label="Enter First Name"
                          name="firstName"
                          type="text"
                          logo={edit}
                        />
                        <FormInput
                          label="Enter Last Name"
                          name="lastName"
                          type="text"
                          logo={edit}
                        />
                        <div className="signup-page-container-right-content-form-btn">
                          <center>
                            <Button
                              type="submit"
                              text="Next"
                              shape="btn-first"
                            />
                          </center>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
              <div className="signup-page-container-right-content-loading">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;

import { authConstant } from "./../constants";
import fileDownload from "js-file-download"
import {
  register,
  login,
  GetAllUsers,
  CreditAccount,
  VerifyOTP,
  ResetPassword,
  ForgetPassword,
  DebitAccount,
  LogOut,
  GetLoginAdminHistory,
  SendInviteEmail,
  SuspendAccount,
} from "./../../http";
import axios from "axios";

export const VerifyToken = (tokens) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.VERIFY_TOKEN_REQUEST });
    try {
      const token = localStorage.getItem("userToken");
      const result = await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/app/auth/verify/token`,
        { token: tokens },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "", //the token is a variable which holds the token
          },
        }
      );
      const { data } = result;
      if (data.valid === false) {
        dispatch({
          type: authConstant.VERIFY_TOKEN_FAILURE,
          payload: { err: "Token Expired" },
        });
      }
    } catch (error) {
      dispatch({
        type: authConstant.VERIFY_TOKEN_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const Register = (user) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.USER_REGISTER_REQUEST });
    try {
      const token = localStorage.getItem("adminToken");
      await axios.post(`${process.env.REACT_APP_ROOT}/v1/app/admin`, user, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: authConstant.USER_REGISTER_SUCCESS,
        payload: "Admin Created Successfully",
      });
    } catch (error) {
      dispatch({
        type: authConstant.USER_REGISTER_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const Login = (admin) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.USER_LOGIN_REQUEST });
    try {
      const result = await login(admin);
      const { data } = result;
      dispatch({
        type: authConstant.USER_LOGIN_SUCCESS,
        payload: "Login Successfully",
      });
      localStorage.setItem("adminToken", data.tokens.access.token);
      localStorage.setItem("adminRefreshToken", data.tokens.refresh.token);
      localStorage.setItem("admin", JSON.stringify(data.user));
    } catch (error) {
      dispatch({
        type: authConstant.USER_LOGIN_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const getAllUsers = (page) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.GET_ALL_USERS_REQUEST });
    try {
      const token = localStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/app/user`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "", //the token is a variable which holds the token
          },
        }
      );

      const { data } = result;
      dispatch({
        type: authConstant.GET_ALL_USERS_SUCCESS,
        payload: { results: data.results, totalPagesOfUser: data.totalPages },
      });
    } catch (error) {
      dispatch({
        type: authConstant.GET_ALL_USERS_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const getAllAdmin = (page) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.GET_ALL_ADMIN_REQUEST });
    try {
      const token = localStorage.getItem("adminToken");
      const result = await axios.get(
       // `${process.env.REACT_APP_ROOT}/v1/app/admin?page=${page}&limit=8`,  
        `${process.env.REACT_APP_ROOT}/v1/app/admin`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "", //the token is a variable which holds the token
          },
        }
      );

      const { data } = result;
      dispatch({
        type: authConstant.GET_ALL_ADMIN_SUCCESS,
        payload: { results: data.results, totalPagesOfUser: data.totalPages },
      });
    } catch (error) {
      dispatch({
        type: authConstant.GET_ALL_ADMIN_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const getLoginAdminHistory = (page) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.ADMIN_HISTORY_REQUEST });
    try {
      const token = localStorage.getItem("adminToken");
      const result = await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/app/transaction/admin/details?page=${page}&limit=8`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "", //the token is a variable which holds the token
          },
        }
      );
      const { data } = result;
      dispatch({
        type: authConstant.ADMIN_HISTORY_SUCCESS,
        payload: { results: data.results, totalPagesOfAdmin: data.totalPages },
      });
    } catch (error) {
      dispatch({
        type: authConstant.ADMIN_HISTORY_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const sendInviteEmail = (email) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.SEND_INVITE_REQUEST });
    try {
      // await SendInviteEmail(email)
      const token = localStorage.getItem("adminToken");
      await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/app/user/invite/user`,
        email,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: authConstant.SEND_INVITE_SUCCESS,
        payload: "Invitation Sent",
      });
    } catch (error) {
      dispatch({
        type: authConstant.SEND_INVITE_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const ExportUser = () => {
  return async (dispatch) => {
    dispatch({ type: authConstant.EXPORT_USER_REQUEST });
    try {
      // await SendInviteEmail(email)
      const token = localStorage.getItem("adminToken");
      const result =await axios.get(
        `${process.env.REACT_APP_ROOT}/v1/app/user/export/user/info`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const {data} = result
      fileDownload(data, 'userReport.csv');
      // dispatch({
      //   type: authConstant.SEND_INVITE_SUCCESS,
      //   payload: "Invitation Sent",
      // });
    } catch (error) {
      dispatch({
        type: authConstant.EXPORT_USER_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};


export const creditAccount = (id, amount, description) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.CREDIT_ACCOUNT_REQUEST });
    try {
      const token = localStorage.getItem("adminToken");
      await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/app/account/credit/${id}`,
        { amount: amount, description },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch(getAllUsers(1));
      dispatch(getLoginAdminHistory());
      dispatch({
        type: authConstant.CREDIT_ACCOUNT_SUCCESS,
        payload: "Balance Added Successfully",
      });
    } catch (error) {
      dispatch({
        type: authConstant.CREDIT_ACCOUNT_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const debitAccounts = (id, amount, description) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.DEBIT_ACCOUNT_REQUEST });
    try {
      const token = localStorage.getItem("adminToken");
      await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/app/account/debit/${id}`,
        { amount: amount, description },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      // await DebitAccount(id, amount, description)
      dispatch(getAllUsers(1));
      dispatch(getLoginAdminHistory());
      dispatch({
        type: authConstant.DEBIT_ACCOUNT_SUCCESS,
        payload: "Balance Deducted Successfully",
      });
    } catch (error) {
      dispatch({
        type: authConstant.DEBIT_ACCOUNT_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const suspendAccount = (id, suspend) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.SUSPEND_ACCOUNT_REQUEST });
    try {
      const token = localStorage.getItem("adminToken");
      await axios.patch(
        `${process.env.REACT_APP_ROOT}/v1/app/user/${id}`,
        { suspend: suspend },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      // await SuspendAccount(id, suspend)
      dispatch(getAllUsers(1));
      dispatch({
        type: authConstant.SUSPEND_ACCOUNT_SUCCESS,
        payload: "Successfully Done!",
      });
    } catch (error) {
      dispatch({
        type: authConstant.SUSPEND_ACCOUNT_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const logOut = (tokens) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.LOGOUT_REQUEST });
    try {
      const token = localStorage.getItem("adminToken");
      await axios.post(
        `${process.env.REACT_APP_ROOT}/v1/app/admin/logout`,
        { refreshToken: tokens },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      localStorage.clear();
      // dispatch({
      //   type: authConstant.LOGOUT_SUCCESS,
      //   payload: "Logout Successfully",
      // });
    } catch (error) {
      dispatch({
        type: authConstant.LOGOUT_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const forgetPassword = (email) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.FORGET_PASSWORD_REQUEST });
    try {
      await ForgetPassword(email);
      dispatch({
        type: authConstant.FORGET_PASSWORD_SUCCESS,
        payload: "Sent Sucessfully! Kindly Check Your Email",
      });
    } catch (error) {
      dispatch({
        type: authConstant.FORGET_PASSWORD_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const verifyOtp = (email, otp) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.VERIFY_OTP_REQUEST });
    try {
      await VerifyOTP(email, otp);
      dispatch({
        type: authConstant.VERIFY_OTP_SUCCESS,
        payload: "OTP Verified!",
      });
    } catch (error) {
      dispatch({
        type: authConstant.VERIFY_OTP_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const resetPassword = (id, newPassword) => {
  return async (dispatch) => {
    dispatch({ type: authConstant.CHANGE_PASSWORD_REQUEST });
    try {
      await ResetPassword(id, newPassword);
      dispatch({
        type: authConstant.CHANGE_PASSWORD_SUCCESS,
        payload: "Password Changed Successfully",
      });
    } catch (error) {
      dispatch({
        type: authConstant.CHANGE_PASSWORD_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};
// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: authConstant.CLEAR_ERRORS });
};

// Clearing Messages
export const clearMessages = () => async (dispatch) => {
  dispatch({ type: authConstant.CLEAR_MESSAGES });
};

import React, { useEffect } from "react";
import "./SignUpComplete.scss";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import logo from "../../assest/login/cong.svg";

const SignUpComplete = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="signup-complete">
        <div className="signup-complete-container">
          <div className="signup-complete-container-left">
            <Sidebar />
          </div>
          <div className="signup-complete-container-right">
            <div className="signup-complete-container-right-content">
              <div className="signup-complete-container-right-content-logo">
                <img src={logo} alt="logo" />
                <br />
                <center>
                  <h3 style={{ color: "white" }}>ADEELBUX</h3>
                </center>
              </div>
              <div className="signup-complete-container-right-content-heading">
                <h1>Sign Up Complete!</h1>
              </div>
              {/* <div className="signup-complete-container-right-content-subheading">
                <h3>Continue to dashboard?</h3>
              </div> */}
              <div className="signup-complete-container-right-content-btn">
                <center onClick={() => navigate("/")}>
                  <Button type="submit" text="Login" shape="btn-first" />
                </center>
              </div>
              <br />
              <div className="signup-complete-container-right-content-loading">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpComplete;

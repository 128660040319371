import React, { useState, useEffect } from "react";
import "./Otp.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// icons
import logo from "../../assest/login/logo.svg";
import mail from "../../assest/login/mail.svg";
import toast, { Toaster } from "react-hot-toast";
import { verifyOtp, clearErrors, clearMessages } from "./../../store/actions";
const Otp = () => {
  const { message, errors, loading } = useSelector(
    (state) => state.authReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let email;
  const { state } = useLocation();
  if (state) {
    email = state;
  }

  const validate = Yup.object({
    otp: Yup.number().required("OTP is Required"),
    // .min(4, "OTP must have 4 character's")
    // .max(4, "You can Enter only 4 character's"),
  });
  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message) {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/changePassword", { state: email }), 2000);
    }
  }, [errors, message]);
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            fontSize: "18px",
          },
        }}
      />
      <div className="otp">
        <div className="otp-content">
          <div className="otp-content-logo">
            <img src={logo} alt="logo" />
            <br />
            <center>
              <h3 style={{ color: "white" }}>ADEELBUX</h3>
            </center>
          </div>
          <div className="otp-content-heading">
            <h1>Enter Confirmation Code</h1>
          </div>
          <div className="otp-content-form">
            <Formik
              initialValues={{
                otp: "",
              }}
              validationSchema={validate}
              onSubmit={(values) => {
                const { otp } = values;
                dispatch(verifyOtp(email, otp));
              }}
            >
              {(formik) => (
                <div>
                  <Form>
                    <FormInput
                      label="Enter OTP Code"
                      name="otp"
                      type="number"
                      logo={mail}
                    />

                    <div className="otp-content-form-btn">
                      <center>
                        <Button
                          type="submit"
                          text={loading ? "Loading" : "Confirm"}
                          shape="btn-first"
                        />
                      </center>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;

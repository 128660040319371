import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import "./ChangedPassword.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import { useLocation, useNavigate } from "react-router-dom";
// icons
import logo from "../../assest/login/logo.svg";
import mail from "../../assest/login/mail.svg";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  resetPassword,
  clearErrors,
  VerifyToken,
  clearMessages,
} from "./../../store/actions";
const ChangedPassword = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { message, errors, loading, verifyTokenFail } = useSelector(
    (state) => state.authReducer
  );
  const navigate = useNavigate();
  let email;
  const { state } = useLocation();
  if (state) {
    email = state;
  }
  const validate = Yup.object({
    password: Yup.string()
      .required("Enter Password")
      .min(8, "Password should be minimum 8 characters long"),
    confirm_password: Yup.string()
      .required("Enter Confirm Password")
      .min(8, "Password should be minimum 8 characters long")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  useEffect(() => {
    dispatch(VerifyToken(id));
    if (verifyTokenFail === true) {
      navigate("/notvalid");
      dispatch(clearErrors());
      dispatch(clearMessages());
    }
  }, [verifyTokenFail]);

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message !="") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/"), 2000);
    }
  }, [errors, message]);
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            fontSize: "18px",
          },
        }}
      />
      <div className="otp">
        <div className="otp-content">
          <div className="otp-content-logo">
            <img src={logo} alt="" />
            <br />
            <center>
              <h3 style={{ color: "white" }}>ADEELBUX</h3>
            </center>
          </div>
          <div className="otp-content-heading">
            <h1>Change Password</h1>
          </div>
          <div className="otp-content-form">
            <Formik
              initialValues={{
                password: "",
                confirm_password: "",
              }}
              validationSchema={validate}
              onSubmit={(values) => {
                const { password } = values;
                dispatch(resetPassword(id, password));
              }}
            >
              {(formik) => (
                <div>
                  <Form>
                    <FormInput
                      label="Enter new password"
                      name="password"
                      type="password"
                      logo={mail}
                    />

                    <br />

                    <FormInput
                      label="Confirm password"
                      name="confirm_password"
                      type="password"
                      logo={mail}
                    />
                    <div className="otp-content-form-btn">
                      <center>
                        <Button
                          type="submit"
                          text={loading ? "Loading..." : "Change"}
                          shape="btn-first"
                        />
                      </center>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangedPassword;

import React, { useEffect } from "react";
import "./SignUpTwo.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { Register, clearMessages, clearErrors } from "./../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
// Yup and Formik
import { Formik, Form } from "formik";
import * as Yup from "yup";

//  Components
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import Loading from "../../components/Loading/Loading";

// Images from react icon
import logo from "../../assest/login/logo.svg";
import mail from "../../assest/login/mail.svg";
import key from "../../assest/login/key.svg";

const SignUpTwo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userFirstName = "";
  let userLastName = "";
  const { state } = useLocation();
  const { message, errors, loading } = useSelector(
    (state) => state.authReducer
  );
  if (state) {
    let { firstName, lastName } = state;
    userFirstName = firstName;
    userLastName = lastName;
  }
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be minimum 8 characters long"),
  });

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
      setTimeout(() => navigate("/signup"), 2000);
    }
  }, [message, errors]);

  const handleBackBtn = () => {
    navigate("/first", { state: state });
  };
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            fontSize: "18px",
          },
        }}
      />
      <div className="signup-two">
        <div className="signup-two-container">
          <div className="signup-two-container-left">
            <Sidebar />
          </div>
          <div className="signup-two-container-right">
            <div className="signup-two-container-right-content">
              <div className="signup-two-container-right-content-logo">
                <img src={logo} alt="logo" />
                <br />
                <center>
                  <h3 style={{ color: "white" }}>ADEELBUX</h3>
                </center>
              </div>
              <div className="signup-two-container-right-content-heading">
                <center>
                  <h1 style={{ color: "white" }}>Create an Admin Account</h1>
                </center>
              </div>
              <div className="signup-two-container-right-content-form">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={validate}
                  onSubmit={(values) => {
                    const { email, password } = values;
                    const user = {
                      firstName: userFirstName,
                      lastName: userLastName,
                      email,
                      password,
                    };
                    dispatch(Register(user));
                    // navigate("/third", {
                    //   state: {
                    //     firstName: userFirstName,
                    //     lastName: userLastName,
                    //     values,
                    //   },
                    // });
                  }}
                >
                  {(formik) => (
                    <div>
                      <Form>
                        <FormInput
                          label="Enter Email Address"
                          name="email"
                          type="email"
                          logo={mail}
                        />
                        <FormInput
                          label="Enter Password"
                          name="password"
                          type="password"
                          logo={key}
                        />
                        <div className="signup-two-container-right-content-form-btn">
                          <Button
                            type="submit"
                            text="Back"
                            shape="btn-second"
                            onClick={() => handleBackBtn()}
                          />
                          <Button
                            type="password"
                            text={loading ? "creating..." : "Create"}
                            shape="btn-first"
                          />
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
              <div className="signup-two-container-right-content-loading">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpTwo;

import React, { useState, useEffect } from "react";
import "./ForgetPassword.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../components/Button/Button";
import FormInput from "../../components/FormInput/FormInput";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  forgetPassword,
  clearErrors,
  clearMessages,
} from "./../../store/actions";
import toast, { Toaster } from "react-hot-toast";
// icons
import logo from "../../assest/login/logo.svg";
import mail from "../../assest/login/mail.svg";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState("");
  const { message, errors, loading, showAlert } = useSelector(
    (state) => state.authReducer
  );
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }
    if (message !="") {
      navigate("/forgetPasswords");
      dispatch(clearMessages());
    }
  }, [errors, message]);
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            fontSize: "18px",
          },
        }}
      />
      <div className="forget">
        <div className="forget-content">
          <div className="forget-content-logo">
            <img src={logo} alt="logo" />
            <br />
            <center>
              <h3 style={{ color: "white" }}>ADEELBUX</h3>
            </center>
          </div>
          <div className="forget-content-heading">
            <h1>Reset Password</h1>
          </div>
          <div className="forget-content-form">
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={validate}
              onSubmit={(values) => {
                dispatch(forgetPassword(values));
                const { email } = values;
                setUserEmail(email);
              }}
            >
              {(formik) => (
                <div>
                  {showAlert ? (
                    ""
                  ) : (
                    <Form>
                      <FormInput
                        label="Enter email to reset password"
                        name="email"
                        type="email"
                        logo={mail}
                      />

                      <div className="forget-content-form-btn">
                        <center>
                          <Button
                            type="submit"
                            text={loading ? "Sending Email" : "Get Email"}
                            shape="btn-first"
                          />
                        </center>
                      </div>
                    </Form>
                  )}
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  HashRouter,
  Routes,
  Navigate,
} from "react-router-dom";
import NewMember from "./screen/NewMember/NewMember";
import Login from "./screen/Login/Login";
import DashBoardTwo from "./screen/DashBoardTwo/DashBoardTwo";
import AccessDenied from "./components/AccessDenied/AccessDenied";
import ProtectedRoute from "./components/ProTectedRoute/ProTectedRoute";
import ProtectLoginToute from "./components/ProtectLoginRoute/ProtectLoginRoute";
import ForgetPassword from "./screen/ForgetPassword/ForgetPassword";
import Otp from "./screen/Otp/Otp";
import ChangedPassword from "./screen/ChangedPassword/ChangedPassword";
import SignUpFirst from "./screen/SignUp/SignUp";
import SignUpTwo from "./screen/SignUpTwo/SignUpTwo";
import SignUpThree from "./screen/SignUpThree/SignUpThree";
import SignupComplete from "./screen/SignUpComplete/SignUpComplete";
import TokenNotValid from "./components/TokenNotValid/TokenNotValid";
import ForgetPasswords from "./screen/ForgetPasswords/ForgetPasswords";

const Routess = () => {
  return (
    <>
      {/* <Router> */}
      <HashRouter>
        <Routes>
          <Route path="/forgetPassword" element={<ForgetPassword />}></Route>
          <Route path="/forgetPasswords" element={<ForgetPasswords />}></Route>
          <Route path="/otp" element={<Otp />}></Route>
          <Route
            path="/changePassword/:id"
            element={<ChangedPassword />}
          ></Route>
          <Route path="/first" element={<SignUpFirst />}></Route>
          <Route path="/second" element={<SignUpTwo />}></Route>
          <Route path="/third" element={<SignUpThree />}></Route>
          <Route path="/signup" element={<SignupComplete />}></Route>
          <Route element={<ProtectLoginToute />}>
            <Route path="/" element={<Login />}></Route>
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboardTwo" element={<DashBoardTwo />}></Route>
            <Route path="/newMember" element={<NewMember />}></Route>
          </Route>
          <Route
            path="/not-found"
            element={<h1 className="text-white">Not found</h1>}
          />
          <Route path="/notvalid" element={<TokenNotValid />} />
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
        {/* </Router> */}
      </HashRouter>
    </>
  );
};

export default Routess;

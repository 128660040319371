import React, { useState, useEffect, CSSProperties } from "react";
import Left from "../../components/Left/Left";
import Middle from "../../components/Middle/Middle";
import Right from "../../components/Right/Right";
import "./DashBoardTwo.scss";
import addperson from "../../assest/add.png";
import { useNavigate } from "react-router-dom";
import whitedown from "../../assest/whitedownarrow.png";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { makeStyles } from "@mui/styles";
import HashLoader from "react-spinners/HashLoader";
import {
  getAllUsers,
  clearErrors,
  creditAccount,
  debitAccounts,
  logOut,
  getLoginAdminHistory,
  clearMessages,
  suspendAccount,
  ExportUser,
  getAllAdmin
} from "./../../store/actions";
import MyVerticallyCenteredModal from "./Modal";
import moment from "moment";
import Pagination from "@mui/material/Pagination";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#DC8A59",
};

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
    },
  },
}));

const DashBoardTwo = () => {
  const classes = useStyles();
  const [modalShow, setModalShow] = React.useState(false);
  const [debitAccount, setDebitAccount] = React.useState(false);
  const [accountId, setAccountId] = React.useState("");
  const [transName, setTransName] = React.useState(false);
  const [page, setPage] = useState(1);
  const [newpage, setNewPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userAdminData,setUserAdminData] = useState([]);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(new Date());
  const currentDay = new Date().getDate();
  const [searchFilter,setSearchFilter] = useState("");
  let [color, setColor] = useState("#DC8A59");

  const {
    users,
    admins,
    history,
    errors,
    message,
    loading,
    logOutLoading,
    totalPagesOfAdmin,
    totalPagesOfUser,
  } = useSelector((state) => state.authReducer);

  
  
  const handleSearchFilter = (e) =>{

    setSearchFilter(e.target.value);

  }
  const handleHidefunction = (
    amount,
    description,
    setAmount,
    setDescription
  ) => {
    if (description === "") {
      toast.error("Description is required");
    } else {
      setModalShow(false);
      setTransName(false);
      if (debitAccount === true) {
        if (amount > 0 && description != "") {
          dispatch(debitAccounts(accountId, amount, description));
        }
        setDebitAccount(false);
        setAmount("");
        setDescription("");
        setTransName(false);
      } else {
        if (amount > 0 && description != "") {
          dispatch(creditAccount(accountId, amount, description));
          setAmount("");
          setDescription("");
          setTransName(false);
        }
      }
    }
  };


  useEffect(() => {
    dispatch(getAllUsers(page));
    dispatch(getAllAdmin(page))
    
    
  }, [page,dispatch]);

  useEffect(() =>{
    if (users.length > 0){
      console.log('users loaded')
      setUserAdminData (users.concat(admins))
      
    }
  },[users,admins])


  useEffect(() => {
    
    if (users.length > 0 && searchFilter.length>0 ){
    let data = users.concat(admins).filter(user => {
        return (user.firstName+' '+ user.lastName).toLowerCase().includes(searchFilter.toLowerCase())
        || user.email.toLowerCase().includes(searchFilter.toLowerCase())   
       })
         setUserAdminData(data);
      }

  },[searchFilter,users,admins])


  useEffect(() => {
    dispatch(getLoginAdminHistory(newpage));
  }, [newpage]);

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errors);
      dispatch(clearErrors());
    }

    if (message != "") {
      toast.success(message);
      dispatch(clearMessages());
    }
  }, [message, errors]);

  const user = JSON.parse(localStorage.getItem("admin"));
  //  Acordian

  const handleDebit = (id) => {
    setModalShow(true);
    setDebitAccount(true);
    setTransName(true);
    setAccountId(id);
  };
  const handleCredit = (id) => {
    setModalShow(true);
    setAccountId(id);
  };

  const handleLogout = async () => {
    dispatch(logOut(localStorage.getItem("adminRefreshToken")));
    setTimeout(() => navigate("/"), 1000);
  };
  // Acordian
  const [value, setValue] = useState(false);
  const handleClick = () => {
    setValue(!value);
  };

  const handleExportUser = () => {
    dispatch(ExportUser());
  };
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            fontSize: "18px",
          },
        }}
      />
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={(amount, description, setAmount, setDescription) =>
          handleHidefunction(amount, description, setAmount, setDescription)
        }
        transName={transName}
      />
      <div className="dashboardtwo">
        <div className="dashboardtwo-container">
          <div className="dashboardtwo-container-left">
            <Left />
          </div>
          <div className="dashboardtwo-container-middle">
            <Middle>
              <div className="dashboardtwo-container-middle-content">
                <div className="dashboardtwo-container-middle-content-heading">
                  <p>Welcome back</p>
                  <h2>{user ? `${user.firstName} ${user.lastName}` : "..."}</h2>
                </div>
                <div className="dashboardtwo-container-middle-content-container">
                  <div className="dashboardtwo-container-middle-content-container-flex">
                    <h1>User's Details</h1>
                    <div className="dashboardtwo-container-middle-content-container-flex-btn">
                      <div className="dashboardtwo-container-middle-content-container-flex-btn-first">
                      
                        <input 
                          type="text"
                          className={`newinput-container-input search-input`}
                          autoComplete="off"
                          onChange={handleSearchFilter}
                          placeholder="Search User">  
                          </input>
                      </div>
                      <div className="dashboardtwo-container-middle-content-container-flex-btn-second">
                        <button onClick={() => navigate("/first")}>
                          <img src={addperson} alt="" />
                          Add New Admin
                        </button>
                      </div>
                      <div className="dashboard-btn-flex">
                        <div
                          className="dashboardtwo-container-middle-content-container-flex-btn-second"
                          style={{ cursor: "pointer" }}
                        >
                          <button onClick={() => handleExportUser()}>
                            <img src={addperson} alt="" />
                            Export User's Data
                          </button>
                        </div>
                        <div className="dashboardtwo-container-middle-content-container-flex-btn-second">
                          <button onClick={() => navigate("/newMember")}>
                            <img src={addperson} alt="" />
                            Add New Member
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashboardtwo-container-middle-content-container-tabel ">
                    <div className="content scrollit">
                      <table cellSpacing="0">
                        <thead>
                          <tr>
                            <th className="tabel-heading-first center">Name</th>

                            <th className="bordered">Email </th>
                            <th className="center">Balance</th>
                            <th className="center">Role</th>
                            <th className="bordered center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        
                          
                          {userAdminData.length >0 ? (
                            userAdminData.map((data, ind) => {
                              return (
                                <tr key={ind}>
                                  <td className="td-white td-white-first td-border">
                                    <div></div>
                                    {data.firstName} {data.lastName}
                                  </td>
                                  <td className=" td-white bordered t-l td-border">
                                    {data.email}
                                  </td>
                                  <td className=" td-white bordered td-border">
                                    {/* ${data.account.amount?data.account.amount:0}.00 */}
                                    ${data.account === undefined || data.account === null ? '':data.account.amount}
                                  </td>
                                  <td className=" td-white bordered td-border">
                                    {data.role}
                                  </td>
                                  {data.role === "User" ? (
                                    <td className="btn-td td-border">
                                      <button
                                        className="td-btn"
                                        onClick={() =>
                                          handleDebit(data.account.id)
                                        }
                                      >
                                        SUBTRACT
                                      </button>
                                      <button
                                        className="td-btn"
                                        onClick={() =>
                                          handleCredit(data.account.id)
                                        }
                                      >
                                        ADD
                                      </button>
                                      <button
                                        className="td-btn"
                                        onClick={() =>
                                          dispatch(
                                            suspendAccount(
                                              data.account.user,
                                              !data.suspend
                                            )
                                          )
                                        }
                                      >
                                        <div
                                          className={
                                            data.suspend === false
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {data.suspend === false
                                            ? "SUSPEND"
                                            : "UNSUSPEND"}
                                        </div>
                                      </button>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colspan="5" className="loader">
                              <HashLoader
                              color={color}
                              loading={true}
                              cssOverride={override}
                              size={70}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                            
                            </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* <Pagination
                      className="pagination"
                      count={totalPagesOfUser}
                      page={page}
                      classes={{ ul: classes.ul }}
                      size="medium"
                      showFirstButton
                      showLastButton
                      onChange={(e, value) => setPage(value)}
                    /> */}
                  </div>
                  <div className="dashboardtwo-container-middle-content-container-flex">
                    <h1 className="p-t">Recent Activity</h1>
                    <div className="dashboardtwo-container-middle-content-container-flex-btn">
                      <div className="dashboardtwo-container-middle-content-container-flex-btn-first">
                        {/* <button>See All</button> */}
                      </div>
                    </div>
                  </div>

                  <div className="dashboardtwo-container-middle-content-container-tabel mt-1">
                    <div className="content">
                      <table cellSpacing="0">
                        <thead>
                          <tr>
                            <th className="tabel-heading-second center ">
                              Date / Time
                            </th>
                            <th className="bordered tabel-heading-first center">
                              Type of Activity
                            </th>
                            <th className="bordered tabel-heading-first center">
                              Transaction Amount
                            </th>
                            <th className="tabel-heading-first center">Name</th>
                            <th className="tabel-heading-first center">
                              Description
                            </th>
                            {/* <th className="bordered">Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {history.length > 0 ? (
                            history.map((data, ind) => {
                              return (
                                <tr key={ind}>
                                  <td style={{ color: "white" }}>
                                    {moment(data.date).format("MM/DD/YYYY")}
                                  </td>
                                  <td
                                    style={{ color: "white" }}
                                    className=" td-white bordered t-al-le"
                                  >
                                    {data.transactionType === "debit"
                                      ? "Balance was Deducted"
                                      : "Balance was Added"}
                                  </td>
                                  <td style={{ color: "white" }}>
                                    {data.amount}
                                  </td>
                                  <td
                                    style={{ color: "white" }}
                                    className=" td-white bordered t-al-le"
                                  >
                                    {data.user.firstName} {data.user.lastName}
                                  </td>
                                  {/* <td style={{color:"white"}}>
                                  <button className="td-btn">Detail</button>
                                </td> */}
                                  <td
                                    style={{ color: "white" }}
                                    className=" td-white bordered t-al-le"
                                  >
                                    {data.description}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>No Data Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      className="pagination"
                      count={totalPagesOfAdmin}
                      classes={{ ul: classes.ul }}
                      page={newpage}
                      size="medium"
                      showFirstButton
                      showLastButton
                      onChange={(e, value) => setNewPage(value)}
                    />
                  </div>
                </div>
              </div>
            </Middle>
          </div>
          <div className="dashboardtwo-container-right">
            <Right>
              <center>
                <div className="dashboardtwo-container-right-date">
                  <p>{`${currentDay} ${currentMonth} ${currentYear}`}</p>
                </div>
              </center>

              <br />
              <div className="dashboardtwo-container-right-news">
                <div className="dashboardtwo-container-right-news-container">
                  <div className="dashboardtwo-container-right-news-container-heading">
                    <p>Recent Email Activity</p>
                  </div>
                  <div className="dashboardtwo-container-right-news-container-today">
                    <span>Today</span>
                  </div>

                  {history.length > 0 ? (
                    history.map((data, ind) => {
                      return (
                        <div
                          className="dashboardtwo-container-right-news-container-item"
                          key={ind}
                        >
                          <div className="dashboardtwo-container-right-news-container-item-to">
                            <p>To :</p>
                          </div>
                          <div className="dashboardtwo-container-right-news-container-item-name">
                            <p>
                              {data.user.firstName} {data.user.lastName}
                            </p>
                            <div className="dashboardtwo-container-right-news-container-item-name-noti">
                              <i>
                                {data.transactionType === "debit"
                                  ? "Balance was Deducted"
                                  : "Balance was Added"}
                              </i>
                            </div>
                          </div>
                          <div className="dashboardtwo-container-right-news-container-item-arrow">
                            <img src={whitedown} alt="" />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h3 className="not-found">No Data Found</h3>
                  )}
                  <div className="email-button-edit">
                    <button onClick={() => handleLogout()}>LogOut</button>
                  </div>
                </div>
              </div>
            </Right>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardTwo;
